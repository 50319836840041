import React, { useState, useEffect, useRef } from "react";
import loadable from "@loadable/component";
import { motion } from "framer-motion";

import { fadeUp } from "@lib/animation";
import { AnchorNav } from "../../anchorNav";
import { ModuleWrapper, useModuleContext } from "../moduleWrapper";
import { AnchorScrollLink } from "@components/ui/links";
import CloseIcon from "@svg/close.svg";
// import SanityImage from "gatsby-plugin-sanity-image";
import { SanityImage } from "@components/sanity/sanityImage";
import cx from "classnames";

const VideoComponent = loadable(() => import("react-player/lazy"));
const PortalModal = loadable(() => import("@components/ui/portalModal"));

const BannerInner = ({
  title,
  bannerText,
  textBottom,
  bgImage,
  isVideo,
  video,
  scrollLink,
  anchorMenu,
}) => {
  const [videoOpen, setVideoOpen] = useState(false);
  const [domLoaded, setDomLoaded] = useState(false);
  const { inView } = useModuleContext();
  const target = useRef();
  useEffect(() => {
    setDomLoaded(true);
  }, []);

  const navRef = React.useRef(null);
  const bannerRef = React.useRef(null);

  const [navActive, setNavActive] = React.useState(false);

  //detect when navRef is at the top of the page
  React.useEffect(() => {
    const handleScroll = () => {
      if (navRef?.current?.getBoundingClientRect()?.top <= 0) {
        setNavActive(true);
      } else {
        setNavActive(false);
      }
    };
    const handleScroll2 = () => {
      if (target?.current?.getBoundingClientRect().bottom >= 0) {
        setNavActive(false);
      } else {
        setNavActive(true);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    window.addEventListener("scroll", handleScroll2, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", handleScroll2);
    };
  }, [navRef]);

  //get height of navRef
  const [navHeight, setNavHeight] = React.useState(0);
  React.useEffect(() => {
    setNavHeight(
      navRef.current ? navRef?.current?.getBoundingClientRect().height : 0
    );
  }, [navRef]);

  return (
    <>
      <div
        className={cx("w-full h-screen px-gutter relative bg-black flex ", {
          "items-center": !textBottom,
          "items-end": textBottom,
        })}
        ref={target}
      >
        {/* Text */}
        <div
          className={cx("relative z-10 text-white mt-20 md:mt-0", {
            "mb-32": textBottom,
          })}
        >
          <motion.h1
            className="blockH1 mb-one max-w-lg"
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={fadeUp}
            transition={{ duration: 0.8 }}
          >
            {title}
          </motion.h1>
          <motion.h4
            className="blockH4 max-w-xl mb-one"
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={fadeUp}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            {bannerText}
          </motion.h4>
          {/* Links */}
          <motion.div
            className="flex flex-col gap-4 md:flex-row"
            animate={inView ? "visible" : "hidden"}
            initial="hidden"
            variants={fadeUp}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            {scrollLink &&
              scrollLink?.length > 0 &&
              scrollLink?.map((link, i) => (
                <AnchorScrollLink key={i} {...link} className="btn-red" />
              ))}
            {/* {isVideo && (
              <div className="btn-red" onClick={() => setVideoOpen(true)}>
                Watch Video
              </div>
            )} */}
          </motion.div>
        </div>
        {/* Video */}
        {isVideo && domLoaded && (
          <>
            <div className="bg-video absolute w-full h-full top-0 left-0 opacity-40 z-0">
              <VideoComponent
                url={video.teaserVideo}
                playing
                muted
                playsinline
                width="100%"
                loop
              />
            </div>
            <PortalModal isShowing={videoOpen}>
              <div
                className="text-red absolute top-4 right-4 cursor-pointer scale-100 hover:scale-110 transform transition-all duration-150 z-20"
                onClick={() => setVideoOpen(false)}
              >
                <CloseIcon className="fill-current w-6" />
              </div>
              <VideoComponent
                url={video.fullVideo}
                controls
                playing
                width="100%"
                height="100%"
                className="fit-video z-10"
              />
            </PortalModal>
          </>
        )}
        {/* Image */}
        {!isVideo && (
          <SanityImage
            image={bgImage}
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
        )}
      </div>
      {/* Sticky nav */}
      {anchorMenu.length !== 0 && (
        <AnchorNav
          navRef={navRef}
          anchorMenu={anchorMenu}
          navActive={navActive}
        />
      )}
    </>
  );
};

const PageBanner = (props) => {
  return (
    <ModuleWrapper>
      <BannerInner {...props} />
    </ModuleWrapper>
  );
};

export default PageBanner;
