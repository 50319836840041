import React from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import { AnchorScrollLink } from "@components/ui/links";

export const AnchorNav = ({ navRef, navActive, anchorMenu }) => {
  function scrollToValue(value) {
    const element = document.getElementById(value);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  return (
    <div
      ref={navRef}
      id="sticky-nav"
      className={cx(
        "bg-white py-4 lg:py-6 px-gutter w-full flex justify-around items-center  z-40",
        {
          "absolute top-full": !navActive,
          "fixed top-0": navActive,
        }
      )}
    >
      <div className="flex gap-x-16 items-center  z-50">
        {anchorMenu?.map((link, i) => {
          return (
            <div>
              <AnchorScrollLink
                key={i}
                {...link}
                className="text-black block cursor-pointer hover:text-red"
              />
            </div>
          );
        })}
      </div>{" "}
    </div>
  );
};
